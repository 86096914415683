:root {
  --color-brand-bg: #3342ab;
  --color-brand-primary: #3342ab;
}

body {
  margin: 0;
  height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .outer-container {
    display: flex;
    background-color: var(--color-brand-bg);
  }

  // Custom general styling for pages
  .main-content {
    .container-custom {
      padding: 6% 8%;
      border-radius: 16px;
      .event-scroll {
        overflow: none;
        overflow-x: auto;
        display: block;
      }
      ::-webkit-scrollbar {
        display: none;
      }
      h1 {
        color: var(--color-brand-primary);
        font-weight: 600;
      }
      .title-custom {
        border-bottom: 4px solid var(--color-brand-primary);
      }
      // TicketsDisplay component
      .link-custom {
        color: #000000;
        text-decoration: none;
        .card-img {
          height: 250px;
          width: 230px;
          object-fit: cover;
          border-radius: 8px;
        }
        .card-img-overlay {
          .category {
            border-radius: 8px;
          }
        }
      }
    }
    // Custom styling for Ticket component
    .ticket-custom {
      .ticket-cover-img {
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 60vh;
        border-radius: 16px;
      }
      .ticket-card {
        z-index: 2;
        border-radius: 16px;
        margin-top: -25px;
        .fs-12 {
          font-size: 12px;
        }
      }
    }
  }

  .btn {
    outline: none;
    &:focus {
      box-shadow: none;
    }
    &:active {
      opacity: 0.8;
    }
  }

  .form-group {
    margin-bottom: 16px;
    input:focus,
    select:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

// @media only screen and (max-width: 768px) {
//   body {

//   }
// }


/** ================= SCROLLBAR CUSTOMIZATION ================= */

/* width & height */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
