.points-page {
  .card {
    padding: 6% 0%;
    text-align: left;
    margin: 0 auto 24px;
    border-radius: 16px;
    .logo-zone {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      img {
        height: 5vh;
        width: 70%;
        margin-bottom: 10px;
        object-fit: contain;
      }
    }
  }
}